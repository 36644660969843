<template>
  <div id="mydiv" class="text-normal elevation-10 pa-2">
    <div id="mydivheaders" class="ma-n2 d-flex align-center justify-space-between">
      <slot name="header">Click to move</slot>
      <v-icon color="white" @click="show = !show">{{show ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
    </div>
    <div v-if="show" class="mt-5">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
//Make the DIV element draggagle:
// dragElement(document.getElementById("mydiv"));
  data() {
    return {
      show: true,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      element: null
    }
  },
  mounted() {
    this.dragElement();
  },
  methods: {
    dragElement() {
      this.element = document.getElementById("mydiv");
      if (document.getElementById(this.element.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(this.element.id + "header").onmousedown = this.dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        this.element.onmousedown = this.dragMouseDown;
      }
    },
    dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      document.onmouseup = this.closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = this.elementDrag;
    },
    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      // set the element's new position:
      this.element.style.top = (this.element.offsetTop - this.pos2) + "px";
      this.element.style.left = (this.element.offsetLeft - this.pos1) + "px";
    },
    closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
}
</script>
<style scoped>
#mydiv {
  position: fixed;
  padding:5px;
  z-index: 9;
  left:10%;
  top:10%;
  background-color: #f1f1f1;
  cursor: move;
  min-width: 160px;
  max-width: 400px;
}
#mydiv :deep(p) {
  margin-bottom: 10px !important;
}
#mydivheaders {
  padding: 5px;
  margin-bottom:5px;
  cursor: move;
  z-index: 10;
  background-color: #2196F3;
  color: #fff;
}
</style>